import React, { useState, useEffect } from 'react';
import { Wrap, TrustedSiteTitle, NoneContentWrap, ListContainer } from './styled';
import { observer } from 'mobx-react-lite';
import useTypeStore from '../../hooks/useTypeStore';
import TrustedSiteItem from './item';
import SportsLoader from '../../components/sportsLoader';

import PrerenderedLoadable from '../../components/prerenderLoadable';

const TrustedSite = observer(() => {
  const communityStore = useTypeStore('communityStore');

  const [list, setList] = useState([]);

  useEffect(() => {
    communityStore.actionTrustedList();
  }, []);

  useEffect(() => {
    setList(communityStore.trustedSites);
  }, [communityStore.trustedSites]);

  return (
    <Wrap>
      <TrustedSiteTitle>네임드 보증사이트</TrustedSiteTitle>

      {communityStore.loading ? (
        <SportsLoader />
      ) : !list || list.length === 0 ? (
        <NoneContentWrap>
          <div></div>
          <img src='/images/ic-trusted-site.svg' />
          <span>현재 등록된 추천 보증 사이트가 없습니다.</span>
        </NoneContentWrap>
      ) : (
        <ListContainer>
          {list.map((item, i) => (
            <TrustedSiteItem key={item.wr_id + i} item={item} />
          ))}
        </ListContainer>
      )}
    </Wrap>
  );
});

export default TrustedSite;
