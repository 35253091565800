import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ImageUtil } from '../../../common/utils/imageUtil';

const TrustedSiteItem = ({ item }) => {
  const {
    wr_id,
    wr_subject,
    wr_name,
    member_info,
    wr_site_name,
    wr_deposit,
    wr_operation_start,
    wr_verification_period,
    wr_note,
    wr_banner_url,
  } = item;

  const authorIcon =
    member_info?.level === 'mp'
      ? '/img/lv/23/mp.png'
      : `/img/lv/23/${member_info.gender === 'm' ? `m${member_info.level}` : `f${member_info.level}`}.png`;
  const authorImage = ImageUtil.imageUrl(authorIcon, 100, 100);

  return (
    <StyledLink to={`/trusted_site/detail?id=${wr_id}&type=trusted_site`}>
      <Container>
        <ThumbImage src={wr_banner_url} alt={wr_subject} />
        <Info>
          <SiteName>{wr_site_name}</SiteName>
          <UserContainer>
            <LevelIcon src={authorImage} />
            <span>{wr_name}</span>
          </UserContainer>
          {/*<RowContainer>*/}
          {/*  <Label>보증금:</Label>*/}
          {/*  <Value>{wr_deposit}</Value>*/}
          {/*</RowContainer>*/}
          {/*<RowContainer>*/}
          {/*  <Label>운영 시작:</Label>*/}
          {/*  <Value>{wr_operation_start}</Value>*/}
          {/*</RowContainer>*/}
          {/*<RowContainer>*/}
          {/*  <Label>검증기간:</Label>*/}
          {/*  <Value>{wr_verification_period}</Value>*/}
          {/*</RowContainer>*/}
          <RowContainer>
            <Label>특징:</Label>
            <Value>{wr_note}</Value>
          </RowContainer>
        </Info>
      </Container>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #333;

  &:visited {
    color: #999;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f4f4f4;
  padding: 8px 12px;
  gap: 8px;

  @media (min-width: 768px) {
    border-bottom: 0px;
  }
`;

const ThumbImage = styled.img`
  width: 165px;
  height: 124px;
  object-fit: cover;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  flex: 1;
`;

const SiteName = styled.span`
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
  //-webkit-line-clamp: 2;
  /*NOS-967*/
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 12px;
  color: #333;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: 12px;
  color: #333;
`;

const LevelIcon = styled.img`
  width: 22px;
  height: 22px;
`;

const Label = styled.div`
  font-size: 12px;
  color: #222;
  font-weight: semi-bold;
`;

const Value = styled.div`
  font-size: 12px;
  color: #333;
  font-weight: semi-bold;
  word-break: break-word;
  flex: 1;
  /*NOS-967*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export default TrustedSiteItem;
