import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Tabs, DefaultTabBar } from 'rmc-tabs';
import { Menu } from '../../common/constants/menu';
import useCheckLogin from '../../hooks/useCheckLogin';
import useModalStore from '../../hooks/useModalStore';
import NoticeBarLayer from '../nav/noticeBarLayer';

const SubNav = ({ type }) => {
  const { isCheckLogin } = useCheckLogin();
  const modal = useModalStore();
  const tabsRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const matches = useRouteMatch({
    path: location.pathname,
    strict: true,
    sensitive: true,
  });
  const [scrolled, setScrolled] = useState(false);
  const [initialPage] = useState(location.pathname.split('/')[2]);
  const menuType = useMemo(() => {
    const type = location.pathname.split('/')[1];

    if (matches) {
      return type;
    } else {
      return undefined;
    }
  }, [matches, location]);
  const [currentTabKey, setCurrentTabKey] = useState(Menu[type][0].key);

  const onClickTab = ({ key }) => {
    const link = type === 'main' ? `/${key}` : `/${menuType}/${key}`;

    let subLink = '';

    if (link === '/customercenter/qna') {
      subLink = '/list';
    }
    // Safely check if the interestNav exists
    const interestNav = document.querySelector('.my');
    if (interestNav) {
      // interestNav.classList.remove('selected');
      if (interestNav.classList.contains('selected')) {
        const button = document.querySelector('.my'); // Assuming you want to click the button with this class
        if (button) {
          button.click(); // Trigger the button click
        }
      }
    }

    setCurrentTabKey(key);
    history.push(link + subLink);
  };

  useEffect(() => {
    const path = location.pathname.split('/');

    if (path[2] !== currentTabKey) {
      setCurrentTabKey(path[2]);
    }
  }, [location.pathname, currentTabKey]);

  useEffect(() => {
    const lastKey = Menu[type].findIndex((item) => item.key === currentTabKey) === Menu[type].length - 1;

    if (lastKey && tabsRef.current) {
      tabsRef.current.setState({
        // transform: 'translate3d(-40%, 0px, 0px)'
      });
    }
  }, [currentTabKey, tabsRef]);

  useEffect(() => {
    const lastKey = Menu[type].findIndex((item) => item.key === initialPage) === Menu[type].length - 1;

    if (lastKey) {
      tabsRef.current.setState({
        // transform: 'translate3d(-40%, 0px, 0px)'
      });
    }

    let prevScrollPosition = window.pageYOffset;
    window.onscroll = () => {
      let currentScrollPos = window.pageYOffset;
      if (currentScrollPos <= 0) {
        setScrolled(false);
      } else if (prevScrollPosition > 48 && prevScrollPosition < currentScrollPos) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      prevScrollPosition = currentScrollPos;
    };
  }, []);

  const onClickCommunityWrite = () => {
    if (!isCheckLogin) {
      modal.openConfirmModal({
        message: '로그인 후 사용이 가능 합니다. 로그인 하시겠습니까?',
        okCallback: () => history.push('/auth/login'),
      });
      return;
    } else {
      history.push('/community/write');
    }
  };

  return (
    <>
      <nav className={clsx('sub_nav_wrap', menuType === 'community' && 'community_nav', scrolled ? 'hide' : 'show')}>
        {menuType !== 'home' && (
          <Tabs
            tabs={Menu[type]}
            initialPage={initialPage}
            page={currentTabKey}
            usePaged={false}
            noRenderContent={true}
            tabBarBackgroundColor=''
            onTabClick={onClickTab}
            renderTabBar={(props) => (
              <DefaultTabBar
                {...props}
                renderUnderline={() => null}
                ref={tabsRef}
                renderTab={(tab) => {
                  if (menuType === 'mypage' && tab.key === 'gifticon') {
                    return (
                      <div>
                        <div className={'blinking big_new_icon'} />
                        <div className='tab'>{tab.title}</div>
                      </div>
                    );
                  } else if (menuType === 'minigame' && tab.key === 'ladder') {
                    return (
                      <div>
                        <div className={'blinking big_new_icon'} />
                        <div className='tab'>{tab.title}</div>
                      </div>
                    );
                  } else {
                    return <span className='tab'>{tab.title}</span>;
                  }
                }}
              />
            )}
          />
        )}
        {menuType === 'community' && (
          <button className='btn_community_write' onClick={onClickCommunityWrite}>
            글쓰기
          </button>
        )}
      </nav>
      <NoticeBarLayer></NoticeBarLayer>
    </>
  );
};

export default SubNav;
