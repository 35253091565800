import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const GoBack = ({ isRecord = false, fromHome = false }) => {
  const history = useHistory();
  const { location } = history;
  const [recentSports, setRecentSports] = useState('major');
  const queryString = location ?  new URLSearchParams(location.search) : new URLSearchParams();
  const app = queryString.get('app') || null;

  useEffect(() => {
    const recentScorePath = window.localStorage.getItem('recentSports');
    if (recentScorePath !== null) {
      setRecentSports(recentScorePath);
    }
  }, [location]);

  const onClickBack = () => {
    if (fromHome) {
      return history.replace(`/home`);
    }

    if (isRecord) {
      history.push(`/score/${recentSports}`);
    } else {
      if (app && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('on_go_back');
      } else {
        history.goBack();
      }
    }
  };

  return (
    <button className="btn_prev" onClick={onClickBack}>
      이전으로
    </button>
  );
};

export default GoBack;
