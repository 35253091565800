import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import MyInfoLayer from './myInfoLayer';
import IsLogin from '../isLogin';
import useUsersStore from '../../hooks/useUsersStore';
import useTypeStore from '../../hooks/useTypeStore';
import { observer } from 'mobx-react';

//This version value to make sure the browser will always get the latest resource
//when the version value is changed. So remember to change the version value when
//the resource is updated.
const NAV_RESOURCE_VERSION = '1';

const Nav = observer(({ path, onOpenMyInfo }) => {
  const usersStore = useUsersStore();
  const homeStore = useTypeStore('homeStore');
  const inputRef = useRef(null);
  const location = useLocation();
  const [recentSports, setRecentSports] = useState('major');
  const [recentBoard, setRecentBoard] = useState('best');
  const [openMyInfo, setOpenMyInfo] = useState(false);

  useEffect(() => {
    homeStore.setShowMyInfoPane(openMyInfo);
  }, [openMyInfo]);

  useEffect(() => {
    setOpenMyInfo(false);
  }, [path]);

  // Close my info pane when search input is opened in Home
  useEffect(() => {
    if (homeStore.showSearchInput && openMyInfo) {
      setOpenMyInfo(false);
    }
  }, [homeStore.showSearchInput]);

  useEffect(() => {
    const splitPathName = location.pathname.split('/');
    const mainPath = splitPathName[1];
    const subPath = splitPathName[2];
    if (mainPath === 'score') {
      setRecentSports(subPath);
    } else if (mainPath === 'community') {
      setRecentBoard(subPath);
    }
  }, [location.pathname]);

  const onClickTab = (value) => {
    if (path === value && openMyInfo) {
      setOpenMyInfo(false);
    }
  };

  return (
    <div className='NavSection'>
      {/*<NoticeBarLayer></NoticeBarLayer>*/}
      <NavWrap id='nav-wrap'>
        <Link
          to={'/home'}
          className={clsx('home', !openMyInfo && path === 'home' && 'selected')}
          onClick={() => {
            window.reflowOnFocus();
            onClickTab('home');
          }}
        >
          홈
        </Link>
        <Link
          to={`/score/${recentSports}`}
          className={clsx('score', !openMyInfo && path === 'score' && 'selected')}
          onClick={() => {
            window.reflowOnFocus();
            onClickTab('score');
          }}
        >
          스코어
        </Link>
        <Link
          to={'/minigame'}
          className={clsx('game', !openMyInfo && path === 'minigame' && 'selected')}
          onClick={() => {
            window.reflowOnFocus();
            onClickTab('minigame');
          }}
        >
          미니게임
        </Link>
        <Link
          to={'/trusted_site'}
          className={clsx('trusted_site', !openMyInfo && path === 'trusted_site' && 'selected')}
          onClick={() => {
            window.reflowOnFocus();
            onClickTab('trusted_site');
          }}
        >
          보증사이트
        </Link>
        <Link
          to={'/chat'}
          id={'chatNav'}
          className={clsx('chat', !openMyInfo && path === 'chat' && 'selected')}
          onClick={() => {
            window.reflowOnFocus();
            onClickTab('chat');
          }}
        >
          채팅
        </Link>
        <Link
          to={`/community/${recentBoard}`}
          className={clsx('community', !openMyInfo && path === 'community' && 'selected')}
          onClick={() => {
            window.reflowOnFocus();
            onClickTab('community');
          }}
        >
          커뮤니티
        </Link>
        {/*<Link*/}
        {/*  to={'/game/holdem'}*/}
        {/*  id={'holdemNav'}*/}
        {/*  className={clsx('holdem')}*/}
        {/*  onClick={() => {*/}
        {/*    window.reflowOnFocus();*/}
        {/*    onClickTab('holdem');*/}
        {/*  }}*/}
        {/*>*/}
        {/*  오픈홀덤*/}
        {/*</Link>*/}
        <IsLogin
          on={
            <MyInfoButton className={clsx('my', openMyInfo && 'selected')}>
              <span className={clsx('thumb', `lv${usersStore.me.level}`)}>
                <img
                  src={usersStore.me.profile_img}
                  onError={(event) => {
                    event.target.src = '/images/thumb_user_def.png';
                  }}
                />
                <svg
                  className='border'
                  version='1.1'
                  id='Layer_1'
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  x='0px'
                  y='0px'
                  viewBox='0 0 36 36'
                  enableBackground='new 0 0 36 36'
                  xmlSpace='preserve'
                >
                  <path d='M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z' />
                </svg>
              </span>
              <input
                ref={inputRef}
                onClick={() => setOpenMyInfo(!openMyInfo)}
                onFocus={() => inputRef.current.blur()}
              />
            </MyInfoButton>
          }
          off={
            <MyInfoButton className={clsx('my', openMyInfo && 'selected')}>
              <input
                ref={inputRef}
                onClick={() => setOpenMyInfo(!openMyInfo)}
                onFocus={() => inputRef.current.blur()}
              />
              내정보
            </MyInfoButton>
          }
        />
        <MyInfoLayer
          openMyInfo={openMyInfo}
          showHomesSearch={homeStore.showSearchInput}
          setOpenMyInfo={setOpenMyInfo}
        />
      </NavWrap>
    </div>
  );
});

// const NoticeList = styled.ul`
//   background-color: #fff;
//   position: absolute;
//   bottom: 60px;
//   li {
//     //border-bottom: 1px solid #f2f2f2;
//     a {
//       //padding: 16px 6px;
//       font-size: 15px;
//       color: #222;
//       line-height: 19px;
//       display: block;
//       em {
//         color: #ed2026;
//         margin-right: 5px;
//       }
//     }
//   }
// `;

const NavWrap = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  height: 55px;
  width: 100%;
  z-index: 100;
  border-top: 1px solid rgba(230, 232, 234, 0.5);
  box-sizing: border-box;
  :before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    right: 0;
    left: 0;
    height: 6px;
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.05));
  }
  > a {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px;
    :before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
    }
    &.chat {
      color: #a7a9ae;
      :before {
        background: url('/images/ic-cheers.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
        background-size: auto 18px;
      }
      &.selected {
        color: #d70000;
        :before {
          background: url('/images/ic-cheers-on.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
          background-size: auto 18px;
        }
      }
    }
    &.home {
      color: #a7a9ae;
      :before {
        background: url('/images/ic-home-mobile.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
        background-size: auto 18px;
      }
      &.selected {
        color: #d70000;
        :before {
          background: url('/images/ic-home-mobile-on.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
          background-size: auto 18px;
        }
      }
    }
    &.score {
      color: #a7a9ae;
      :before {
        background: url('/images/ic-score.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
        background-size: auto 18px;
      }
      &.selected {
        color: #d70000;
        :before {
          background: url('/images/ic-score-on.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
          background-size: auto 18px;
        }
      }
    }
    &.community {
      color: #a7a9ae;
      :before {
        background: url('/images/ic-community.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
        background-size: auto 18px;
      }
      &.selected {
        color: #d70000;
        :before {
          background: url('/images/ic-community-on.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
          background-size: auto 18px;
        }
      }
    }
    &.game {
      color: #a7a9ae;
      :before {
        background: url('/images/ic-game.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
        background-size: auto 18px;
      }
      &.selected {
        color: #d70000;
        :before {
          background: url('/images/ic-game-on.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
          background-size: auto 18px;
        }
      }
    }
    &.trusted_site {
      color: #a7a9ae;
      :before {
        background: url('/images/ic-trusted-site.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
        background-size: auto 18px;
      }
      &.selected {
        color: #d70000;
        :before {
          background: url('/images/ic-trusted-site-on.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
          background-size: auto 18px;
        }
      }
    }
    &.holdem {
      :before {
        background: url('/images/ic-nav-holdem.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
        background-size: auto 18px;
      }
    }
  }
`;

const MyInfoButton = styled.label`
  position: relative;
  :before {
    display: none;
  }

  &.my {
    & > input {
      width: 0;
      height: 0;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
    }
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px;
    color: #a7a9ae;
    :before {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      margin-bottom: 0px;
    }
    :before {
      background: url('/images/ic-my.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
      background-size: auto 18px;
    }
    &.selected {
      color: #d70000;
      :before {
        background: url('/images/ic-my-on.svg?v=${NAV_RESOURCE_VERSION}') no-repeat center center;
        background-size: auto 18px;
      }
    }
  }

  span {
    position: absolute;
    width: 33px;
    height: 33px;
    :before {
      position: absolute;
      content: '';
      top: -1px;
      left: -1px;
      width: 35px;
      height: 35px;
      z-index: 1;
    }
    img {
      width: 33px;
      height: 33px;
      border-radius: 100%;
    }
    .border {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: 35px;
      height: 35px;
    }
  }
`;

export default Nav;
