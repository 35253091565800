import React, { useState } from 'react';
import clsx from 'clsx';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { CommentList } from '../../comment';
import { Url } from '../../../../../common/constants/environment';
import Seo from '../../../../../components/seo';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import useUsersStore from '../../../../../hooks/useUsersStore';

const CommunityDetailView = observer(
  ({
    item,
    type,
    actionCommentAdd,
    openReport,
    actionDelete,
    onClickRecommend,
    page,
    category,
    gameId,
    sportsId,
    sportsType,
  }) => {
    const {
      ca_name,
      wr_subject,
      date,
      wr_hit,
      wr_content,
      user,
      wr_name,
      is_me,
      wr_id,
      comment,
      mb_id,
      is_good,
      wr_good,
      game_id,
      home_team,
      away_team,
    } = item;
    const usersStore = useUsersStore();

    const history = useHistory();
    const [openOption, toggleOpenOption] = useState(false);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const fromHome = query.get('fromHome') === 'true';

    const onClickOption = () => {
      toggleOpenOption(!openOption);
    };

    const onClickReport = () => {
      toggleOpenOption(false);
      openReport(true);
    };

    const onClickUrlCopy = () => {
      const sharedUrl = document.createElement('textarea');

      sharedUrl.value = window.location.href;
      sharedUrl.setAttribute('readonly', '');
      sharedUrl.style.position = 'absolute';
      sharedUrl.style.left = '-9999px';

      document.body.appendChild(sharedUrl);

      sharedUrl.select();
      sharedUrl.setSelectionRange(0, 9999);

      const returnValue = document.execCommand('copy');

      document.body.removeChild(sharedUrl);

      if (!returnValue) {
        throw new Error('copied nothing');
      }

      onClickOption();
      alert('Url이 클립보드에 복사되었습니다.');
    };

    const thumbImgPathReplace = (wrContent) => {
      wrContent = wrContent.replace('http://data.named.com/data', Url.dataThumbUrl);

      return wrContent;
    };

    const onClickCommentCheck = (actionType) => {
      if (!!comment && comment.length > 0 && !gameId) {
        let alertText = actionType === 'modify' ? '수정이' : '삭제가';
        alert(`댓글이 등록된 게시글은 ${alertText} 불가능합니다.`);
        return;
      }

      if (actionType === 'modify') {
        let url = `/community/write?id=${wr_id}&type=${type}`;
        if (!!gameId) {
          url += `&gameId=${gameId}&sportsType=${sportsType}&sportsId=${sportsId}`;
        } else {
          url += `&page=${page}`;
        }
        history.push(url);
      } else {
        if (!window.confirm('정말 삭제 하시겠습니까?')) {
          return;
        }

        actionDelete({ id: wr_id, boardType: type }).then((res) => {
          if (res.code === 200) {
            if (!!gameId) {
              history.push(`/match/${sportsType}/${gameId}/analysis`);
            } else {
              if (type === 'trusted_site') {
                history.push('/trusted_site');
              } else {
                history.push(`/community/${type}`);
              }
            }
          } else {
            alert(res.message);
          }
        });
      }
    };

    const getPrevContentName = () => {
      if (type === 'trusted_site') {
        return '보증사이트';
      }

      return !!gameId
        ? sportsId === '2'
          ? `${away_team} vs ${home_team}`
          : `${home_team} vs ${away_team}`
        : category !== 'best'
        ? category !== 'recent'
          ? ca_name
          : '새글'
        : '베스트';
    };

    const onClickClose = (event) => {
      if (fromHome) {
        event.preventDefault();
        return history.goBack();
      }
    };

    return (
      <div className='board_view'>
        <Seo title={`커뮤니티 ${ca_name === '테마파크' ? '미니게임' : ca_name} 상세`} />

        <div className='view_area'>
          <h3 className='blind'>게시글</h3>
          <div className='summary'>
            <Link
              className='category'
              to={(() => {
                if (type === 'trusted_site') {
                  return '/trusted_site';
                } else if (gameId) {
                  return `/match/${sportsType}/${gameId}/analysis`;
                } else {
                  return `/community/${category}?page=${page}`;
                }
              })()}
              onClick={onClickClose}
            >
              {getPrevContentName()}
            </Link>
            <p className='subject'>{wr_subject}</p>
            <p className='article_info'>
              <span className='date'>{date}</span>
              <span className='count'>조회 {wr_hit}</span>
            </p>
            <div className='user_info'>
              <Link className='lnk_user' to={`/user/${mb_id}`}>
                <span className={clsx('thumb', `lv${user.level}`)}>
                  <img
                    src={user.profile_img}
                    onError={(event) => {
                      event.target.src = '/images/thumb_user_def.png';
                    }}
                  />
                  <svg
                    className='border'
                    version='1.1'
                    id='Layer_1'
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    x='0px'
                    y='0px'
                    viewBox='0 0 36 36'
                    enableBackground='new 0 0 36 36'
                    xmlSpace='preserve'
                  >
                    <path d='M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z' />
                  </svg>
                </span>
                <strong className='name'>{wr_name}</strong>
                <span className='today'>{user.today_word}</span>
              </Link>
              <button type='button' className='btn_option' onClick={onClickOption}>
                옵션
              </button>
              {openOption && (
                <div className='ly_option'>
                  <ul>
                    {(is_me || (usersStore?.data?.is_admin && type === 'trusted_site')) && (
                      <li>
                        <button className='lnk' onClick={() => onClickCommentCheck('modify')}>
                          수정
                        </button>
                      </li>
                    )}
                    {(is_me || (usersStore?.data?.is_admin && type === 'trusted_site')) && (
                      <li>
                        <button className='lnk' onClick={() => onClickCommentCheck('delete')}>
                          삭제
                        </button>
                      </li>
                    )}
                    {!is_me && (
                      <li>
                        <button className='lnk' onClick={onClickReport}>
                          신고하기
                        </button>
                      </li>
                    )}
                    <li>
                      <button className='lnk' onClick={onClickUrlCopy}>
                        URL 복사
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className='article_cont'>
            <div
              dangerouslySetInnerHTML={{
                __html: thumbImgPathReplace(wr_content),
              }}
            />
          </div>
          <div className={'sticky'}>
            <button type={'button'} className={clsx('recommend', is_good ? 'on' : 'off')} onClick={onClickRecommend} />
          </div>
        </div>

        <CommentList item={item} boardType={type} actionCommentAdd={actionCommentAdd} />
      </div>
    );
  }
);

export default CommunityDetailView;
