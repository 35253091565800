import { enableGiftIcon } from '../../../routes/mypage/index';

export const Menu = {
  main: [
    { key: 'score', title: '스포츠', value: 0 },
    { key: 'minigame', title: '미니게임', value: 0 },
    { key: 'lottery', title: '파워키노', value: 0 },
    // { key: 'fxgame', title: 'FX게임', value: 0 },
    { key: 'community', title: '커뮤니티', value: 0 },
    // { key: 'media', title: '미디어', value: 0 },
    { key: 'customercenter', title: '고객센터', value: 0 },
  ],
  score: [
    { key: 'major', title: '주요경기', value: 0 },
    { key: 'interest', title: '관심경기', value: 0 },
    { key: 'soccer', title: '축구', value: 0 },
    { key: 'baseball', title: '야구', value: 0 },
    { key: 'basketball', title: '농구', value: 0 },
    { key: 'volleyball', title: '배구', value: 0 },
    { key: 'hockey', title: '아이스하키', value: 0 },
    { key: 'football', title: '미식축구', value: 0 },
    { key: 'lol', title: 'LOL', value: 0 },
    { key: 'starcraft', title: '스타크래프트', value: 0 },
    { key: 'proto', title: '프로토', value: 0 },
  ],
  chat: [{ key: '', title: '채팅', value: 0 }],
  home: [{ key: '', title: '홈', value: 0 }],
  trusted_site: [{ key: '', title: '보증사이트', value: 0 }],
  minigame: [
    { key: 'rball', title: '런닝볼', value: 0 },
    { key: 'nball', title: 'N파워볼', value: 0 },
    { key: 'redball', title: '레드볼', value: 0 },
    { key: 'ladder', title: '사다리', value: 0 },
    { key: 'holdem', title: 'ODDS홀덤', value: 0 },
  ],
  fxgame: [
    { key: '1min', title: 'FX게임 1분', value: 0 },
    { key: '2min', title: 'FX게임 2분', value: 0 },
    { key: '3min', title: 'FX게임 3분', value: 0 },
    { key: '4min', title: 'FX게임 4분', value: 0 },
    { key: '5min', title: 'FX게임 5분', value: 0 },
  ],
  community: [
    // { key: 'recent', title: '새글', value: 0 },
    { key: 'best', title: '베스트', value: 0 },
    { key: 'free', title: '자유', value: 0 },
    // { key: 'humor', title: '유머', value: 0 },
    { key: 'park', title: '후기', value: 0 },
    { key: 'verification', title: '보증게시판', value: 0 },
    { key: 'sports_analysis', title: '분석', value: 0 },
    // { key: 'betble', title: '벳블 내역공유', value: 0 },
    // { key: 'trade', title: '모의투자', value: 0 },
    // { key: 'park', title: '미니게임', value: 0 },
    // { key: 'crime', title: '피해사례공유', value: 0 },
    { key: 'crime', title: '제보/검증', value: 0 },
    { key: 'photo', title: '포토', value: 0 },
    { key: 'attend', title: '출석부', value: 0 },
  ],
  media: [
    { key: 'sportsnews', title: '스포츠뉴스', value: 0 },
    { key: 'column', title: '네임드 칼럼', value: 0 },
  ],
  mypage: [
    { key: 'info', title: '내정보', value: 0 },
    { key: 'blacklist', title: '차단', value: 0 },
    { key: 'subscribe', title: '친구', value: 0 },
    { key: 'cash', title: '캐시', value: 0 },
    { key: 'item', title: '아이템', value: 0 },
    { key: 'market', title: '마켓', value: 0 },
    ...(enableGiftIcon ? [{ key: 'gifticon', title: '기프티콘', value: 0 }] : []),
    { key: 'trade', title: '황금마차', value: 0 },
    { key: 'exp', title: '경험치', value: 0 },
    { key: 'point', title: '포인트', value: 0 },
    { key: 'medal', title: '건빵', value: 0 },
    { key: 'loginlog', title: '로그인기록', value: 0 },
    { key: 'leave', title: '탈퇴', value: 0 },
  ],
  customercenter: [
    { key: 'notice', title: '공지사항', value: 0 },
    { key: 'qna', title: '문의하기', value: 0 },
    { key: 'faq', title: '도움말', value: 0 },
    { key: 'develop', title: '개발자노트', value: 0 },
  ],
};
