import React, { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import Score from './score';
import Design from './_html';
import Main from './main';
import ScoreMatch from './score/match';
import ProtoCalcRoutes from './score/protoCalculator/protoCalcRoutes';
import PrerenderedLoadable from '../components/prerenderLoadable';
import RouteGuard from '../components/routeGuard';
import useUsersStore from '../hooks/useUsersStore';
import CustomerCenter from './customercenter';
import TermsUse from '../components/terms/termsUse';
import PersonalInfo from '../components/terms/personalInfo';
import Terms from './terms';
import Game from '../routes/game';
import Home from './home';
import TrustedSite from './trusted_site';
import TrustedSiteDetail from './trusted_site/trusted_site_detail';
import HomeBoardDetail from './home/home_board_detail';
import SubNav from '../components/subNav';
import Nav from '../components/nav';
import User from './user';
import InterestMatch from './score/interest';
import ChatHtml from './_html/chat';
import { observer } from 'mobx-react';
import useModalStore from '../hooks/useModalStore';

const AuthComponent = PrerenderedLoadable(() => import('../routes/auth'));
const CommunityComponent = PrerenderedLoadable(() => import('../routes/community'));
const MypageComponent = PrerenderedLoadable(() => import('../routes/mypage'));
const WriteComponent = PrerenderedLoadable(() => import('../routes/community/write'));

const Routes = observer(() => {
  const usersStore = useUsersStore();
  const modal = useModalStore();
  const location = useLocation();
  const history = useHistory();
  const mainPath = location.pathname.split('/')[1];

  useEffect(() => {
    //    usersStore.getInfo();
    const today = new Date().toDateString();
    const lastShownDate = localStorage.getItem(`lastModalShowDate_${usersStore.me.mb_id}`);

    const need_phone_verify_after_reset_pw = lastShownDate !== today && usersStore.me.need_phone_verify_after_reset_pw;
    const need_phone_verify = lastShownDate !== today && usersStore.me.need_phone_verify;

    if (need_phone_verify_after_reset_pw) {
      localStorage.setItem(`lastModalShowDate_${usersStore.me.mb_id}`, today);
      modal.openConfirmModal({
        title: '휴대전화 인증하시고 7,000 포인트를 놓치지 마세요!',
        message: ``,
        messageJsx: (
          <div>
            <p style={{ marginBottom: '10px' }}>
              더욱 안전하고 편리한 <strong>네임드</strong> 서비스를 위해 <strong>휴대전화 본인 인증 기능</strong>이
              추가되었습니다.
            </p>
            <p style={{ marginBottom: '10px' }}>
              지금 인증하시면 <strong style={{ color: '#DF330C' }}>무려 7,000 포인트</strong>를 즉시 지급해 드립니다!
            </p>
            <p style={{ marginBottom: '25px' }}>
              5월 9일 이후에는 <strong style={{ color: '#185BB1' }}>3,000 포인트만</strong>지급됩니다.
            </p>
            <p>
              <strong>마이페이지</strong> > <strong>내 정보</strong> > <strong>인증 정보에서</strong> 바로 인증하시고,{' '}
              <strong style={{ color: '#DF330C' }}>7,000 포인트</strong>를 꼭 받아가세요!
            </p>
          </div>
        ),
        okButtonText: '인증 바로가기',
        cancelButtonText: '취소',
        showSymbol: true,
        okCallback: () => {
          history.push('/auth/phone_auth');
        },
      });
    }
    if (need_phone_verify) {
      localStorage.setItem(`lastModalShowDate_${usersStore.me.mb_id}`, today);
      modal.openConfirmModal({
        title: '휴대전화 인증하시고 3,000 포인트를 놓치지 마세요!',
        message: ``,
        messageJsx: (
          <div>
            <p style={{ marginBottom: '10px' }}>
              더욱 안전하고 편리한 <strong>네임드</strong> 서비스를 위해 <strong>휴대전화 본인 인증 기능</strong>이
              추가되었습니다.
            </p>
            <p style={{ marginBottom: '25px' }}>
              지금 인증하시면 <strong style={{ color: '#DF330C' }}>무려 3,000 포인트</strong>를 즉시 지급해 드립니다!
            </p>
            <p>
              <strong>마이페이지</strong> > <strong>내 정보</strong> > <strong>인증 정보에서</strong> 바로 인증하시고,{' '}
              <strong style={{ color: '#DF330C' }}>3,000 포인트</strong>를 꼭 받아가세요!
            </p>
          </div>
        ),
        okButtonText: '인증 바로가기',
        cancelButtonText: '취소',
        showSymbol: true,
        okCallback: () => {
          history.push('/auth/phone_auth');
        },
      });
    }
  }, [usersStore.me.need_phone_verify, usersStore.me.need_phone_verify_after_reset_pw]);

  return (
    <Switch>
      <Route exact path='/auth/*' component={AuthComponent} />
      <RouteGuard exact path='/community/write' component={WriteComponent} />
      <Route exact path='/match/:type/:id/:category' component={ScoreMatch} />
      <Route exact path='/score/proto/*' component={ProtoCalcRoutes} />
      <Route exact path='/page/service' component={TermsUse} />
      <Route exact path='/page/privacy' component={PersonalInfo} />
      <Route exact path='/terms/:type' component={Terms} />
      <Route exact path='/user/:userId' component={User} />
      <div id='wrap' className={mainPath}>
        <div id='container'>
          {mainPath && <SubNav type={mainPath} key={mainPath} />}
          <Route exact path='/chat' component={ChatHtml} />
          <Route exact path='/' component={Main} />
          <Route exact path={['/home']} component={Home} />
          <Route exact path={['/home/*']} component={HomeBoardDetail} />
          <Route exact path={['/trusted_site']} component={TrustedSite} />
          <Route exact path={['/trusted_site/*']} component={TrustedSiteDetail} />
          <Route exact path={['/minigame', '/minigame/*']} component={Game} />
          <Route
            exact
            path='/score/:type(major|soccer|baseball|basketball|volleyball|hockey|football|lol|starcraft|proto)'
            component={Score}
          />
          <Route exact path='/score/interest' component={InterestMatch} />
          <Route exact path='/community/*' component={CommunityComponent} />
          <Route exact path='/mypage/*' component={MypageComponent} />
          <Route exact path='/customercenter/*' component={CustomerCenter} />
          <Route exact path='/_html/:type' component={Design} />
          {mainPath && <Nav path={mainPath} />}
          {/*<div className={'footerText'} style={{backgroundColor: 'white', padding: '10px', fontSize: '10px',borderTop:'1px solid #d6d6d6'}}>*/}
          {/*    <span>회사명: (주)케이제이프로퍼티 | 대표: 황지현 | 이메일: help@named.com</span><br/>*/}
          {/*    <span>전화번호: 070-4337-0808 | 주소: 서울특별시 서초구 사평대로68, 지1층</span><br/>*/}
          {/*    <span>사업자등록번호 709-87-02955 | 통신판매업신고번호 제 2024-서울서초-0918호</span><br/>*/}
          {/*    <span>Copyright &copy; KJ PROPERTY. All rights reserved</span>*/}
          {/*</div>*/}
        </div>
      </div>
    </Switch>
  );
});

export default Routes;
