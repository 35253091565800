import React, { useEffect, useRef, useState } from 'react';
import {
  Wrap,
  Header,
  BackButton,
  TotalDeleteButton,
  List,
  Content,
  Subject,
  Date,
  DeleteButton,
  Dot,
  Sumarry,
  NoneContentWrap,
} from './styled';
import { observer } from 'mobx-react-lite';
import useUsersStore from '../../../../hooks/useUsersStore';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'images/common-function/close3.svg';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import SportsLoader from '../../../sportsLoader';

const NotifyLayer = observer(({ setOpenAlarm, setOpenMyInfo }) => {
  const notyWrap = useRef();
  const usersStore = useUsersStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    usersStore.getNofifyList().then((r) => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (setOpenAlarm) {
      disableBodyScroll(notyWrap.current);
    }
    return () => {
      enableBodyScroll(notyWrap.current);
    };
  }, [setOpenAlarm]);

  // If SubNav is shown then add padding top to header, otherwise padding top = 0px
  const isSubNavShown = () => {
    const allSubNavWrap = document.querySelectorAll('.sub_nav_wrap');
    if (allSubNavWrap.length > 0) {
      return allSubNavWrap[0].classList.contains('show');
    }
  };

  return (
    <Wrap>
      <Header isSubNavShown={isSubNavShown()}>
        <BackButton onClick={() => setOpenAlarm(false)} />
        <h2>알림</h2>
        <TotalDeleteButton onClick={usersStore.deleteNotifyItemAll}>전체삭제</TotalDeleteButton>
      </Header>
      {loading ? (
        <SportsLoader />
      ) : usersStore.notifyArr.length === 0 ? (
        <NoneContent />
      ) : (
        <List ref={notyWrap}>
          {usersStore.notifyArr.map((item, key) => {
            return (
              <ListItem
                key={key}
                item={item}
                putNotifyItem={usersStore.putNotifyItem}
                deleteNotifyItem={usersStore.deleteNotifyItem}
                setOpenAlarm={setOpenAlarm}
                setOpenMyInfo={setOpenMyInfo}
              />
            );
          })}
        </List>
      )}
    </Wrap>
  );
});

const ListItem = ({ item, putNotifyItem, deleteNotifyItem, setOpenAlarm, setOpenMyInfo }) => {
  const history = useHistory();

  const onClickItem = ({ is_read, rel_type, rel_table, rel_id, notify_id }) => {
    let path = '';

    if (rel_type === 'COMMENT_WRITE' || rel_type === 'COMMENT_REPLY') {
      if (rel_table === 'develop' || rel_table === 'notice') {
        path = `/customercenter/${rel_table}/${rel_id}`;
      } else {
        path = `/community/${rel_table}?id=${rel_id}&type=${rel_table}`;
      }
    } else if (rel_type === 'GIFT_ITEM') {
      if (rel_table !== "PHONE_VERIFICATION") {
        path = '/mypage/item?tab=giftList&category=from';
      } else {
        path = '/mypage/point';
      }
    } else if (rel_type === 'GIFT_TP') {
      path = '/mypage/tp';
    } else if (rel_type === 'POINT_WRITE') {
      if (rel_table === '@exp') {
        path = '/mypage/exp';
      } else {
        path = '/mypage/point';
      }
    }

    if (is_read) {
      putNotifyItem(notify_id)
        .then((res) => {
          history.push(path);
        })
        .catch((error) => console.log(error));
    } else {
      history.push(path);
    }

    setOpenAlarm(false);
    setOpenMyInfo(false);
  };

  const deleteItem = ({ notify_id }) => {
    deleteNotifyItem(notify_id);
  };

  return (
    <li>
      <Sumarry onClick={() => onClickItem(item)}>
        {item.is_read === 'N' && <Dot />}
        <Content>
          <Subject>{item.message}</Subject>
          <Date>{item.reg_date}</Date>
        </Content>
      </Sumarry>
      <DeleteButton onClick={() => deleteItem(item)}>
        <CloseIcon />
      </DeleteButton>
    </li>
  );
};

const NoneContent = () => {
  return (
    <NoneContentWrap>
      <img src='/images/ic-none-alarm.svg' />
      <span>알림이 없습니다.</span>
    </NoneContentWrap>
  );
};

export default NotifyLayer;
