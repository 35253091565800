import React, { Component, useEffect } from 'react';
import './Banner.css';

const BannerGA = ({ width, height, gaSlot, containerStyle }) => {
    useEffect(() => {
        try {
            (adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            setTimeout(
                function () {
                    try {
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    } catch (e) {
                    }
                }.bind(this),
                3000
            );
        }
    }, [])

    return (
        <>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7395498441083221"
                    crossOrigin="anonymous"></script>
            {/* <!-- pc 150 --> */}
            {/* <div style={containerStyle}
          dangerouslySetInnerHTML={{
            __html: `<ins class="adsbygoogle"
              style="display:inline-block;width:${width}px;height:${height}px"
              data-ad-client="ca-pub-7395498441083221"
              data-ad-slot="${gaSlot}"></ins>`
          }}
        ></div> */}

            <div style={{
                display:'block',
                height: 0,
                width: window.innerWidth,
            }}>
                <ins className="adsbygoogle"
                     style={{
                         display:'inline-block',
                         position: 'absolute',
                         width: window.innerWidth,
                         left: 0,
                         height: 0,
                         zIndex: -9
                     }}
                     data-ad-format="fluid"
                     data-ad-client="ca-pub-7395498441083221"
                     data-ad-slot={gaSlot}></ins>
            </div>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({ });
            </script>
        </>
    );
}


export default BannerGA ;
   