import Api from '../api';
import { Url } from '../../constants/environment';

class CommunityService extends Api {
  constructor(props) {
    super(props);
  }

  /**
   * @description 최신
   * @returns
   */
  async recent(page = 1) {
    const res = await this.commonAjax('get', Url.named, `community/board/recent`, {
      page,
    });

    return res;
  }

  /**
   * @description 리스트
   * @returns
   */
  async list_trusted_sites() {
    const res = await this.commonAjax('get', Url.namedChallenger, `community/trusted-sites`);

    return res;
  }

  /**
   * @description 리스트
   * @returns
   */
  async list(board_type = 'free', page = 1, search_type = '', search_content = '', filter = '', game_id = '') {
    let params = { board_type, page };

    if (search_type.length > 0) {
      params['search_type'] = search_type;
    }

    if (search_content.length > 0) {
      params['search_content'] = search_content;
    }

    if (filter.length > 0) {
      params['filter'] = filter;
    }

    if (game_id.length > 0) {
      params['game_id'] = game_id;
    }

    const res = await this.commonAjax('get', Url.namedChallenger, `community/board`, params);

    return res;
  }

  /**
   * @description 베스트
   * @returns
   */
  async best(page = 1) {
    const res = await this.commonAjax('get', Url.namedChallenger, `community/best`, {
      page,
    });

    return res;
  }

  /**
   * @description 상세
   * @returns
   */
  async detailTrustedSite(wr_id) {
    if (!wr_id) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `community/trusted-sites/${wr_id}`);

    return res;
  }

  /**
   * @description 상세
   * @returns
   */
  async detail(wr_id, board_type) {
    if (!wr_id || !board_type) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `community/board/${wr_id}`, {
      board_type,
    });

    return res;
  }

  /**
   * @description 추천
   * @returns
   */
  async recommend(wr_id, board_type) {
    if (!wr_id || !board_type) {
      return;
    }

    const form = new URLSearchParams();
    form.append('board_type', board_type);

    const res = await this.commonAjax('put', Url.named, `community/board/${wr_id}/good`, form);

    return res;
  }

  /**
   * @description 댓글 입력
   * @returns
   */
  async commentAdd(wr_id, board_type, content, commentType) {
    if (!wr_id || !board_type || !content || !commentType) {
      return;
    }

    const form = new FormData();
    form.append('board_type', board_type);
    form.append('content', content);
    form.append('comment_type', commentType);

    const res = await this.commonAjax('post', Url.named, `community/board/${wr_id}/comment`, form);

    return res;
  }

  /**
   * @description 대댓글 입력
   * @returns
   */
  async reCommentAdd(wr_id, board_type, content, commentType, commentId) {
    if (!wr_id || !board_type || !content || !commentType) {
      return;
    }

    const form = new FormData();
    form.append('board_type', board_type);
    form.append('content', content);
    form.append('comment_type', commentType);
    form.append('comment_id', commentId);

    const res = await this.commonAjax('post', Url.named, `community/board/${wr_id}/comment`, form);

    return res;
  }

  /**
   * @description 댓글 입력
   * @returns
   */
  async commentModify(wr_id, board_type, content, commentId) {
    if (!wr_id || !board_type || !content) {
      return;
    }

    const form = new URLSearchParams();
    form.append('board_type', board_type);
    form.append('content', content);

    const res = await this.commonAjax('put', Url.named, `community/board/${wr_id}/comment/${commentId}`, form);

    return res;
  }

  /**
   * @description 댓글 삭제
   * @returns
   */
  async commentDelete(wr_id, board_type, commentId) {
    if (!wr_id || !board_type || !commentId) {
      return;
    }

    const res = await this.commonAjax('delete', Url.named, `community/board/${wr_id}/comment/${commentId}`, {
      board_type,
    });

    return res;
  }
  /**
   * @description 글등록
   * @returns
   */
  async save(
    boardType,
    subject,
    content,
    isNotice = 'N',
    commentAllowed = 'N',
    caName = '',
    gameId = '',
    sportsId = ''
  ) {
    if (!boardType || !subject || !content) {
      return;
    }

    const form = new FormData();
    form.append('board_type', boardType);
    form.append('subject', subject);
    form.append('content', content);
    form.append('is_notice', isNotice);
    form.append('comment_allowed', commentAllowed);
    form.append('ca_name', caName);
    if (!!gameId) {
      form.append('game_id', gameId);
      form.append('sports_id', sportsId);
    }

    const res = await this.commonAjax('post', Url.named, `community/board`, form);

    return res;
  }

  /**
   * @description 글수정
   * @returns
   */
  async modify(
    id,
    boardType,
    subject,
    content,
    isNotice = 'N',
    commentAllowed = 'N',
    caName = '',
    gameId = '',
    sportsId = ''
  ) {
    if (!id || !boardType || !subject || !content) {
      return;
    }

    const form = new URLSearchParams();
    form.append('board_type', boardType);
    form.append('subject', subject);
    form.append('content', content);
    form.append('is_notice', isNotice);
    form.append('comment_allowed', commentAllowed);
    form.append('ca_name', caName);
    if (!!gameId) {
      form.append('game_id', gameId);
      form.append('sports_id', sportsId);
    }

    const res = await this.commonAjax('put', Url.named, `community/board/${id}`, form);

    return res;
  }

  /**
   * @description 글삭제
   * @returns
   */
  async delete(id, boardType) {
    if (!id || !boardType) {
      return;
    }

    const res = await this.commonAjax('delete', Url.named, `community/board/${id}`, {
      board_type: boardType,
    });

    return res;
  }

  /**
   * @description 신고하기
   * @returns
   */
  async report({ category, relId, relTable, targetId, content, type }) {
    const form = new FormData();
    form.append('category', category);
    form.append('rel_id', relId);
    form.append('rel_table', relTable);
    form.append('target_id', targetId);
    if (!!type) {
      form.append('type', type);
    }
    if (!!content) {
      form.append('content', content);
    }

    const res = await this.commonAjax('post', Url.named, `community/report`, form);

    return res;
  }
}

export default new CommunityService();
